import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { AuthorizationHeader } from 'domain/enums/authorizationHeader';
import { ContentTypeHeader } from 'domain/enums/contentTypeHeader';
import { ApiParams } from 'domain/model/api/apiParams';
import { Token } from 'domain/model/token';
import { SERVER_API_URL } from 'infrastructure/constant/constants';
import { getAuthToken, removeAuthToken } from 'infrastructure/local-storage';
import { resetAuthentication } from 'infrastructure/store/slice/authentication';

export const createNewApi = ({
  baseUrl = SERVER_API_URL,
  apiName,
  authorizationHeader = AuthorizationHeader.BEARER,
  contentTypeHeader = ContentTypeHeader.JSON,
}: ApiParams) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: async headers => {
      const token: string | undefined = await getAuthToken();
      if (token) {
        headers.set('Authorization', `${authorizationHeader} ${token}`);
      }
      headers.set('Content-Type', contentTypeHeader);
      return headers;
    },
  });

  const baseQueryWithStatusTreatment: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
      await removeAuthToken();
      api.dispatch(resetAuthentication());
    }
    return result;
  };

  return createApi({
    baseQuery: baseQueryWithStatusTreatment,
    endpoints: () => ({}),
    reducerPath: apiName,
  });
};

export const api = createNewApi({ baseUrl: SERVER_API_URL, apiName: 'api' });
