export const AUTH_TOKEN = 'AUTH_TOKEN';

export const setAuthToken = async (token: string): Promise<void> => {
  await localStorage.setItem(AUTH_TOKEN, JSON.stringify(token));
};

export const getAuthToken = async (): Promise<string | undefined> => {
  const token = localStorage.getItem(AUTH_TOKEN);
  return token ? (JSON.parse(token) as string) : undefined;
};

export const removeAuthToken = async (): Promise<void> => {
  await localStorage.removeItem(AUTH_TOKEN);
};
