import { ScreenDimensions } from 'domain/DimentionsType';
import styled from 'styled-components/macro';

interface WindowProps {
  small: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  min-height: 100vh;
  background-color: #1c1b1f;
`;

export const Header = styled.div`
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;

  border-bottom: 2px #f56225 solid;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const LeftContainer = styled.div<WindowProps>`
  display: flex;
  width: ${({ small }) => (!!small ? '100%' : '40%')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 120px;
  padding-bottom: 120px;
`;

export const TextContainer = styled.div<WindowProps>`
  display: flex;
  flex-direction: column;
  max-width: 460px;
  justify-content: center;
  align-items: center;
  padding: 0px ${({ small }) => (!!small ? 30 : 0)}px;
`;

export const Title = styled.span`
  color: #ffffff;
  font-size: 60px;
  text-align: center;
`;
export const Subtitle = styled.span`
  color: #ffb59b;
  font-size: 20px;
  text-align: center;
  margin-top: 16px;
  font-weight: 100;
`;

export const ImageContainer = styled.div`
  display: flex;
  width: 60%;
  justify-content: center;
  align-items: center;
  padding-top: 120px;
  padding-bottom: 120px;
`;

export const BannerImage = styled.img`
  width: 640px;
  height: 560px;
`;

export const ButtonContainer = styled.div<WindowProps>`
  display: flex;
  margin-top: 54px;
  flex-direction: ${({ small }) => (!!small ? 'column' : 'row')};
  width: ${({ small }) => (!!small ? '80%' : '45%')};
  max-width: 460px;
  align-items: center;
  justify-content: space-between;
`;

export const CustomButtom = styled.button<WindowProps>`
  display: flex;
  background-color: #ff8c61;
  height: 40px;
  width: ${({ small }) => (!!small ? 220 : 120)}px;
  margin-bottom: ${({ small }) => (!!small ? 12 : 0)}px;
  padding: 10px 46px;
  border-radius: 100px;
  border: none;
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
`;
export const ButtonText = styled.span`
  font-size: 14px;
  color: #1c1b1f;
  font-weight: bold;
`;
