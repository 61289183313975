import PageNotFound from 'presentation/common/error/page-not-found';
import Home from 'presentation/pages/home/home';
import { Route, Routes } from 'react-router-dom';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path={'/'} element={<Home />} />

      {/*<PrivateRoute accessLevel={AccessLevel.ADMIN} path={'/admin'} component={AdminRoutes} />*/}

      <Route path={'*'} element={<Home />} />
    </Routes>
  );
};

export default AppRoutes;
