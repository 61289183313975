import { store } from 'infrastructure/store/store';
import Theme from 'infrastructure/theme/default';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import App from './App';
import i18n from './infrastructure/language/i18n';
import ErrorBoundary from './presentation/common/error/error-boundary';
import * as serviceWorker from './serviceWorker';
import './index.css';

const rootEl = document.getElementById('root');

ReactDOM.render(
  <ThemeProvider theme={Theme}>
    <ErrorBoundary>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <App />
          {/* If this slows down the app in dev disable it and enable when required  */}
        </I18nextProvider>
      </Provider>
    </ErrorBoundary>
  </ThemeProvider>,
  rootEl
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
