import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuthToken } from '../../local-storage';
import { setAuthentication } from '../slice/authentication';

export const prepareApp = createAsyncThunk('startup/prepareApp', async (_, thunkApi) => {
  const token = await getAuthToken();

  if (token) {
    thunkApi.dispatch(setAuthentication());
  }

  return;
});
