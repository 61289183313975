import { createSlice } from '@reduxjs/toolkit';
import { prepareApp } from '../thunk/startupThunk';

interface StartupState {
  isAppReady: boolean;
}

const initialState: StartupState = {
  isAppReady: false,
};

const startup = createSlice({
  name: 'startup',
  initialState,
  reducers: {
    resetStartup: state => {
      state.isAppReady = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(prepareApp.pending, state => {
      state.isAppReady = false;
    });
    builder.addCase(prepareApp.rejected, state => {
      state.isAppReady = false;
    });
    builder.addCase(prepareApp.fulfilled, state => {
      state.isAppReady = true;
    });
  },
});

export const { resetStartup } = startup.actions;

export default startup.reducer;
