import { combineReducers, configureStore, StoreEnhancer } from '@reduxjs/toolkit';
import { api } from 'data/api';
import authentication from './slice/authentication';
import startup from './slice/startup';

const enhancers: StoreEnhancer[] = [];

export const reducer = combineReducers({
  [api.reducerPath]: api.reducer,
  startup,
  authentication,
});

export const store = configureStore({
  devTools: process.env.__DEV__ != null ?? false,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(api.middleware),
  reducer,
  enhancers: defaultEnhancers => [...defaultEnhancers, ...enhancers],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
