import { useAppSelector } from 'infrastructure/store/hooks';
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppRoutes from './presentation/common/routes/routes';
import { createGlobalStyle } from 'styled-components';

const baseHref = '/';

interface IAppState {}

const GlobalStyle = createGlobalStyle`
        body {
            font-family: 'Open Sans', sans-serif;
        }
    `;

const App: React.FC<IAppState> = props => {
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);

  const checkIfIsAuthenticated = isAuthenticated;

  useEffect(() => {
    if (isAuthenticated) {
      //TODO: Create account request
    } else {
    }
  }, []);

  return (
    <BrowserRouter basename={baseHref}>
      <GlobalStyle />
      <AppRoutes />
    </BrowserRouter>
  );
};

export default App;
