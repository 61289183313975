import React, { useState } from 'react';
import {
  Container,
  Header,
  ContentContainer,
  TextContainer,
  ImageContainer,
  BannerImage,
  Title,
  Subtitle,
  LeftContainer,
  CustomButtom,
  ButtonContainer,
  ButtonText,
} from './styles';
import Logo from 'infrastructure/assets/svg/logo.svg';
import BannerImg from 'infrastructure/assets/png/banner.jpg';

export interface HomeProps {}

export enum ScreenDimensions {
  MOBILE = 480,
  MID_WEB = 1200,
  MID_TABLET = 768,
  TABLET = 1080,
}

type Platform = 'iOS' | 'android';

const Home: React.FC<HomeProps> = props => {
  const [isLoaded, setIsLoaded] = useState(false);

  const onPressButton = (platform: Platform) => {
    switch (platform) {
      case 'iOS':
        window.location.href = 'https://apps.apple.com/br/app/speedshop/id6466179437?l=en-GB';
        break;
      case 'android':
        window.location.href = 'https://play.google.com/store/apps/details?id=com.speedshopstore';
        break;
    }
  };

  const width = window.outerWidth;

  console.log(width);

  return (
    <Container>
      <Header>
        <img src={Logo} style={{ marginLeft: width < ScreenDimensions.TABLET ? 14 : 80 }} />
      </Header>
      <ContentContainer>
        <LeftContainer small={width < ScreenDimensions.TABLET}>
          <TextContainer small={width < ScreenDimensions.TABLET}>
            <Title>Bem vindo ao SpeedShop!</Title>
            <Subtitle>
              Baixe o aplicativo e comece a comprar e vender produtos e motos! Dentro do app você pode ver diversos produtos do mundo do
              Motocross, aproveite para criar sua conta e anunciar gratuitamente!
            </Subtitle>
          </TextContainer>
          <ButtonContainer small={width < ScreenDimensions.TABLET}>
            <CustomButtom
              small={width < ScreenDimensions.TABLET}
              onClick={e => {
                e.preventDefault();
                onPressButton('android');
              }}
            >
              <ButtonText>PlayStore</ButtonText>
            </CustomButtom>
            <CustomButtom
              small={width < ScreenDimensions.TABLET}
              onClick={e => {
                e.preventDefault();
                onPressButton('iOS');
              }}
            >
              <ButtonText>AppleStore</ButtonText>
            </CustomButtom>
          </ButtonContainer>
        </LeftContainer>
        {width > ScreenDimensions.TABLET ? (
          <ImageContainer>
            <BannerImage src={BannerImg} />
          </ImageContainer>
        ) : (
          <></>
        )}
      </ContentContainer>
    </Container>
  );
};

export default Home;
